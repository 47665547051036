import React, { useRef } from 'react';
import { mix, progress, clamp, interpolate } from '@popmotion/popcorn';
import { useStore } from 'src/components/GlobalState';
import useAnimationFrame from 'src/hooks/useAnimationFrame';
import useBounds from 'src/hooks/useBounds';
import Text from 'src/components/Text';
import { useBreakpoint } from '../Breakpoints';

const defaultColors = [
  'rgba(235, 239, 255, 1)',
  '#9dd5f9',
  '#7d28e1',
  '#1d1ed4',
  '#00000000',
];

const C2CGradientTitleAnimation = ({ children, colors, tag, ...other }) => {
  const reflow = useStore((state) => state.reflow);
  const [outer, bounds] = useBounds();
  colors ||= defaultColors;
  const inner = useRef();
  const first = colors[0];
  const last = colors[colors.length - 1];
  const rest = colors.length > 2 ? colors.slice(1, -1).join(', ') + ',' : '';

  useAnimationFrame(() => {
    if (outer.current && inner.current && bounds.current) {
      const p = clamp(
        0,
        1,
        progress(reflow.height, reflow.height * 0.4, bounds.current.y)
      );
      const v = mix(-100, 100, p);
      inner.current.style.backgroundImage = `linear-gradient(
        160deg,
        ${first} ${v}%,
        ${rest}
        ${last} ${v + 100}%
        )`;
      inner.current.style.opacity = interpolate([0, 0.5, 1], [0, 1, 1])(p);
    }
  });

  return (
    <Text as="h2" {...other} variant="aktivTitle" lineHeight={1.2} ref={outer}>
      <span
        ref={inner}
        style={{
          display: 'inline-block',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        {children}
      </span>
    </Text>
  );
};

export default ({ children, color, ...props }) => {
  const isLarge = useBreakpoint(0);
  if (isLarge) {
    return (
      <C2CGradientTitleAnimation {...props}>
        {children}
      </C2CGradientTitleAnimation>
    );
  } else {
    return (
      <Text
        {...props}
        as="h2"
        variant="aktivTitle"
        lineHeight={1.2}
        color={color || 'rgba(235, 239, 255, 1)'}
      >
        {children}
      </Text>
    );
  }
};
