import React from 'react';
import Text from 'src/components/Text';
import Img from 'gatsby-image';
import css from 'src/theme/css';
import SongbirdLogo from 'src/assets/svg/logos/songbird.inline.svg';
import { Grid, Box } from 'src/components/FlexBox';
import { useStaticQuery, graphql } from 'gatsby';
import ModuleWrapper from 'src/components/ModuleWrapper';
import TextStack from 'src/components/TextStack';
import SmartButton, * as smartButton from 'src/components/SmartButton';
import Frame from 'src/components/Frame';
import C2CGradientTitleAnimation from 'src/components/C2CGradientTitleAnimation';
import C2COpacityAnimation from 'src/components/C2COpacityAnimation';

const transitionCss = css({
  transition: 'transform 1000ms',
  transitionTimingFunction: ({ ease }) => ease.expo,
});

export default () => {
  const { imageInAction, gradient, gradientSmall } = useStaticQuery(graphql`
    query {
      imageInAction: contentfulAsset(title: { eq: "c2c-in-action" }) {
        title
        fluid(maxWidth: 2880, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      gradient: contentfulAsset(title: { eq: "c2c-gradient" }) {
        title
        fluid(maxWidth: 2880, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      gradientSmall: contentfulAsset(title: { eq: "c2c-gradient-small" }) {
        title
        fluid(maxWidth: 2880, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  `);

  return (
    <ModuleWrapper
      css={css({ py: 'pageMargin', px: 'pageMargin' })}
      background={{
        color: ['c2cGradientToBottom', 'c2cGradientToBottomLeft'],
        smallImage: gradientSmall,
        largeImage: gradient,
      }}
    >
      <Grid
        gx="2rem"
        gy="4rem"
        gridTemplateColumns={['auto', null, '1fr 1fr']}
        alignItems="center"
        css={css({ width: '100%', px: [0, null, null, null] })}
      >
        <Box
          flexDirection="column"
          alignItems={['center', null, 'start']}
          textAlign={['center', null, 'start']}
          css={css({
            pl: [0, null, 'sectionMargin'],
            mr: [0, null, 'pageMargin'],
          })}
        >
          <TextStack>
            <C2COpacityAnimation>
              <Box
                css={css({
                  maxWidth: '100%',
                  height: '20px',
                  m: ['auto', null, 0],
                  transform: 'translate3d(0, 0, 0)',
                  '& svg': {
                    maxHeight: '100%',
                    maxWidth: '180px',
                  },
                })}
              >
                <SongbirdLogo />
              </Box>
            </C2COpacityAnimation>
            <C2CGradientTitleAnimation
              as="h2"
              colors={['rgba(235, 239, 255, 1)', '#9dd5f9', '#1d1ed400']}
            >
              See Camera to
              <br />
              Cloud in action
            </C2CGradientTitleAnimation>
            <C2COpacityAnimation>
              <Text
                as="p"
                variant="aktiv"
                color="white"
                css={css({ maxWidth: '30em' })}
              >
                Learn how Hollywood productions are bridging the gap between
                production and post, with collaborators located all over the
                world.
              </Text>

              <SmartButton
                action={smartButton.actions.videoModal}
                videoUrl={'https://frame.wistia.com/medias/oyl0ngy07i'}
                label="WATCH THE FILM"
                variant="Play"
                css={css({
                  mt: 3,
                  justifyContent: ['center', null, 'flex-start'],
                })}
              ></SmartButton>
            </C2COpacityAnimation>
          </TextStack>
        </Box>

        <C2COpacityAnimation>
          <Frame
            ratio={[1, 0.7]}
            css={css({
              borderRadius: 2,
              overflow: 'hidden',
              filter: 'drop-shadow(0px 30px 70px rgba(0, 0, 0, 0.5))',
            })}
          >
            <Img
              {...imageInAction}
              css={transitionCss}
              style={{
                position: 'absolute',
                width: '100%',
              }}
            />

            <SmartButton
              label="Watch the film"
              action={smartButton.actions.videoModal}
              videoUrl={'https://frame.wistia.com/medias/oyl0ngy07i'}
              variant="C2CPlay"
            ></SmartButton>
          </Frame>
        </C2COpacityAnimation>
      </Grid>
    </ModuleWrapper>
  );
};
