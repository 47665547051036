import React from 'react';
import css from 'src/theme/css';
import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import ModuleWrapper from 'src/components/ModuleWrapper';
import SmartButton from 'src/components/SmartButton';
import C2COpacityAnimation from '../C2COpacityAnimation';
import C2CGradientTitleAnimation from '../C2CGradientTitleAnimation';
import { Flex } from 'src/components/FlexBox';

export default ({ developerImage }) => {
  return (
    <ModuleWrapper
      colorMode="light"
      css={css({
        px: 'pageMargin',
        pt: 'sectionMargin',
      })}
      background={{ color: 'antiMode' }}
    >
      <TextStack
        css={css({
          alignItems: 'center',
          textAlign: 'center',
        })}
      >
        <C2CGradientTitleAnimation
          colors={[
            '#000000',
            '#1d1ed4',
            '#7d28e1',
            '#9dd5f9',
            'rgba(235, 239, 255, 0)',
          ]}
          color="#000"
          variant="heading.xxl"
        >
          Pricing and availability
        </C2CGradientTitleAnimation>
        <C2COpacityAnimation>
          <Text as="p" variant="aktiv" css={css({ maxWidth: '42em' })}>
            Camera to Cloud is available now on all paid Frame.io plans at no
            additional charge. Flexible, new short-term Enterprise plans let
            customers and studios purchase the service they need for the
            duration of their production.
          </Text>
        </C2COpacityAnimation>
        <C2COpacityAnimation>
          <Flex gx="1.5rem" alignItems="center">
            <SmartButton
              variant="C2C"
              action="Link"
              url="#c2c-pricing"
              label="Contact sales"
            />
            <SmartButton
              variant="Caps"
              action="Link"
              url="/pricing"
              label="View pricing"
              css={css({
                borderBottom: 'solid black 1px',
                '> *': { lineHeight: 1.1 },
              })}
            />
          </Flex>
        </C2COpacityAnimation>
      </TextStack>
    </ModuleWrapper>
  );
};
