import React from 'react';
import css from 'src/theme/css';
import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import ModuleWrapper from 'src/components/ModuleWrapper';
import C2COpacityAnimation from '../C2COpacityAnimation';
import C2CGradientTitleAnimation from '../C2CGradientTitleAnimation';
import BNHLogo from 'src/assets/svg/c2c-bnh-logo.inline.svg';
import { Flex } from 'src/components/FlexBox';
import SmartButton from 'src/components/SmartButton';

export default () => {
  return (
    <ModuleWrapper
      css={css({
        px: 'pageMargin',
        py: 'sectionMargin',
      })}
      background={{ color: 'black' }}
    >
      <TextStack textAlign="center" alignItems="center">
        <C2CGradientTitleAnimation css={css({ maxWidth: '14em' })}>
          Get your C2C gear at{' '}
          <BNHLogo
            style={{
              verticalAlign: 'bottom',
              height: '1.1em',
              margin: '0 0.1em',
            }}
          />
        </C2CGradientTitleAnimation>
        <C2COpacityAnimation>
          <Text
            as="p"
            variant="aktiv"
            color="offWhiteAlpha.9"
            css={css({ maxWidth: '30em' })}
          >
            Get the Camera to Cloud hardware you need. Frame.io has partnered
            with B&amp;H to bring you convenient, organized kits.
          </Text>
        </C2COpacityAnimation>
        <C2COpacityAnimation>
          <Flex
            gx="1.5rem"
            gy={3}
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
          >
            <SmartButton
              variant="C2C"
              action="Link"
              url="https://www.bhphotovideo.com/c/product/1632462-REG/teradek_frame_io_c2c_kit_picture.html"
              label="Get the picture & sound kit"
              isBlank
            />
            <SmartButton
              variant="Underline"
              action="Link"
              url="https://www.bhphotovideo.com/c/product/1632461-REG/teradek_frame_io_c2c_kit_picture.html"
              label="Get the picture kit"
              isBlank
            />
          </Flex>
        </C2COpacityAnimation>
      </TextStack>
    </ModuleWrapper>
  );
};
