import React from 'react';
import Text from 'src/components/Text';
import Img from 'gatsby-image';
import css from 'src/theme/css';
import { Flex } from 'src/components/FlexBox';
import { useStaticQuery, graphql } from 'gatsby';
import ModuleWrapper from 'src/components/ModuleWrapper';
import TextStack from 'src/components/TextStack';
import SmartButton from 'src/components/SmartButton';
import Frame from 'src/components/Frame';
import C2CGradientTitleAnimation from 'src/components/C2CGradientTitleAnimation';
import C2COpacityAnimation from 'src/components/C2COpacityAnimation';
import { useBreakpoint } from '../Breakpoints';

const transitionCss = css({
  transition: 'transform 1000ms',
  transitionTimingFunction: ({ ease }) => ease.expo,
});

export default () => {
  const isLarge = useBreakpoint(1);
  const { image1, image2, gradient, gradientSmall } = useStaticQuery(graphql`
    query {
      image1: contentfulAsset(title: { eq: "c2c-training-thumbnail" }) {
        title
        fluid(maxWidth: 2880, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      image2: contentfulAsset(title: { eq: "c2c-training-thumbnail-small" }) {
        title
        fluid(maxWidth: 2880, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      gradient: contentfulAsset(title: { eq: "c2c-gradient" }) {
        title
        fluid(maxWidth: 2880, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      gradientSmall: contentfulAsset(title: { eq: "c2c-gradient-small" }) {
        title
        fluid(maxWidth: 2880, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  `);

  return (
    <ModuleWrapper
      css={css({ py: 'sectionMargin', px: 'pageMargin' })}
      background={{
        color: ['c2cGradientToBottom', 'c2cGradientToBottomLeft'],
        smallImage: gradientSmall,
        largeImage: gradient,
      }}
    >
      <Flex flexDirection="column" gy={5}>
        <TextStack alignItems="center" textAlign="center">
          <C2CGradientTitleAnimation
            as="h2"
            colors={['rgba(235, 239, 255, 1)', '#9dd5f9', '#1d1ed400']}
          >
            Camera to Cloud training
          </C2CGradientTitleAnimation>
          <C2COpacityAnimation>
            <Text
              as="p"
              variant="aktiv"
              color="offWhiteAlpha.8"
              css={css({ maxWidth: '30em' })}
            >
              Watch Episode 1 of Camera to Cloud training as Michael Cioni and
              Valentina Vee go through the basics.
            </Text>
          </C2COpacityAnimation>
        </TextStack>
        <C2COpacityAnimation>
          <Frame
            ratio={[1.15, 0.389]}
            css={css({ borderRadius: 2, overflow: 'hidden' })}
          >
            <Img
              {...(isLarge ? image1 : image2)}
              css={transitionCss}
              style={{
                position: 'absolute',
                width: '100%',
              }}
            />

            <SmartButton
              action="Link"
              label="Watch the video"
              url={'/c2c-training/episode-1-what-is-frame-io-camera-to-cloud'}
              variant="C2CPlay"
            ></SmartButton>
          </Frame>
        </C2COpacityAnimation>
        <C2COpacityAnimation>
          <Flex
            gx={4}
            gy={4}
            flexDirection={['column', 'row']}
            alignItems="center"
            justifyContent="center"
          >
            <SmartButton
              variant="Knockout"
              action="Link"
              url="/c2c-training"
              label="Explore the series"
            />
          </Flex>
        </C2COpacityAnimation>
      </Flex>
    </ModuleWrapper>
  );
};
