import React, { useEffect, useState } from 'react';
import Layout from 'src/components/Layout';
import FormFullSignUp from 'src/components/Modal/C2CFullSignUp/Form';
import { graphql } from 'gatsby';
import PageMeta from 'src/components/PageMeta';
import C2CHero from 'src/components/C2CHero';
import C2CCaseStudy from 'src/components/C2CCaseStudy';
import C2CTraining from 'src/components/C2CTraining';
import C2CFeatureGraph from 'src/components/C2CFeatureGraph';
import C2CPartners from 'src/components/C2CPartners';
import C2CSaveTime from 'src/components/C2CSaveTime';
import C2CSecurity from 'src/components/C2CSecurity';
import C2CDeveloper from 'src/components/C2CDeveloper';
import C2CPricing from 'src/components/C2CPricing';
import C2CFeatures from 'src/components/C2CFeatures';
import C2CBackground from 'src/components/C2CBackground';
import C2CBNH from 'src/components/C2CBNH';
import { useBreakpoint } from 'src/components/Breakpoints';

export default function C2C({ data }) {
  const [isReady, setIsReady] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const isLarge = useBreakpoint(1);
  useEffect(() => {
    if (!isLarge) {
      setIsReady(true);
    }
  }, [setIsReady, isLarge]);

  useEffect(() => {
    if (isReady) {
      setTimeout(() => {
        setShowNav(true);
      }, 1000);
    }
  }, [setShowNav, isReady]);

  return (
    <>
      {isLarge && (
        <C2CBackground
          image={data.ringImage}
          isReady={isReady}
          setIsReady={setIsReady}
        />
      )}
      <Layout isNavTransparent showNav={showNav} isNavSticky={false}>
        <PageMeta
          image={data.metaImage}
          title="Production, meet cloud"
          description={{
            description:
              'Shoot directly to the Cloud with our latest innovation. Frame.io C2C is the fastest, easiest, and most secure way to get footage from cameras to collaborators — anywhere in the world.',
          }}
        />
        <C2CHero
          isReady={isReady}
          iPadImage={data.iPadImage}
          ringImage={data.ringImage}
        />
        <C2CSaveTime
          gradient={data.gradient}
          gradientSmall={data.gradientSmall}
        />
        <C2CFeatures
          featureImageDigitalCinema={data.featureImageDigitalCinema}
          featureImageDslr={data.featureImageDslr}
          featureImageMobile={data.featureImageMobile}
          featureImage1={data.featureImage1}
          featureImage2={data.featureImage2}
          featureImage3={data.featureImage3}
          featureImage4={data.featureImage4}
        />
        <C2CCaseStudy />
        <C2CFeatureGraph
          featureGraphImage={data.featureGraphImage}
          featureGraphVideo={data.featureGraphVideo}
        />
        <C2CPartners />
        {/* <C2CSecurity
          gradient={data.gradient}
          gradientSmall={data.gradientSmall}
        /> */}
        <C2CPricing />
        <C2CDeveloper developerImage={data.developerImage} />
        <C2CTraining />
        <C2CBNH gradient={data.gradient} gradientSmall={data.gradientSmall} />

        {/* Output the form but hidden for Netlify to pickup */}
        <FormFullSignUp isHidden />
        <form
          data-netlify="true"
          hidden={true}
          name="c2c-stay-up-to-date"
          id="c2c-stay-up-to-date"
          method="post"
        >
          <input type="hidden" name="form-name" value="c2c-stay-up-to-date" />
          <input type="email" name="email" />
          <input type="text" name="company_name" />
          <input type="text" name="job_title" />
        </form>
      </Layout>
    </>
  );
}

// Request all the Images needed here to avoid extra requests later
export const pageQuery = graphql`
  query {
    metaImage: contentfulAsset(title: { eq: "c2c-meta" }) {
      description
      file {
        url
        contentType
        details {
          image {
            height
            width
          }
        }
      }
    }
    canvasImage: contentfulAsset(title: { eq: "c2c-intro-background-alt" }) {
      fluid(maxWidth: 1000, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    ringImage: contentfulAsset(title: { eq: "c2c-intro-background-small" }) {
      file {
        url
        details {
          image {
            width
            height
          }
        }
      }
      fluid(maxWidth: 1000, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    iPadImage: contentfulAsset(title: { eq: "c2c-hero-ipad" }) {
      fluid(maxWidth: 2880, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    featureImageDigitalCinema: contentfulAsset(
      title: { eq: "c2c-featureImageDigitalCinema" }
    ) {
      title
      fluid(maxWidth: 1000, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    featureImageDslr: contentfulAsset(title: { eq: "c2c-featureImageDslr" }) {
      title
      fluid(maxWidth: 1000, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    featureImageMobile: contentfulAsset(
      title: { eq: "c2c-featureImageMobile" }
    ) {
      title
      fluid(maxWidth: 1000, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    featureImage1: contentfulAsset(title: { eq: "c2c-featureImage-1" }) {
      title
      fluid(maxWidth: 2880, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    featureImage2: contentfulAsset(title: { eq: "c2c-featureImage-2" }) {
      title
      fluid(maxWidth: 2880, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    featureImage3: contentfulAsset(title: { eq: "c2c-featureImage-3" }) {
      title
      fluid(maxWidth: 2880, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    featureImage4: contentfulAsset(title: { eq: "c2c-featureImage-4" }) {
      title
      fluid(maxWidth: 2880, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    featureGraphImage: contentfulAsset(
      title: { eq: "c2c-feature-graph-poster" }
    ) {
      title
      fluid(maxWidth: 2880, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    featureGraphVideo: contentfulAsset(title: { eq: "c2c-feature-graph" }) {
      file {
        url
      }
    }
    gradient: contentfulAsset(title: { eq: "c2c-gradient" }) {
      fluid(maxWidth: 2880, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    gradientSmall: contentfulAsset(title: { eq: "c2c-gradient-small" }) {
      fluid(maxWidth: 2880, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    developerImage: contentfulAsset(title: { eq: "c2c-code-snippet" }) {
      title
      fluid(maxWidth: 2880, quality: 70) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
