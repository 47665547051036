import React from 'react';
import Img from 'gatsby-image'; ///withIEPolyfill;
import css from 'src/theme/css';
import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import { Grid, Box } from 'src/components/FlexBox';
import { useStaticQuery, graphql } from 'gatsby';
import ModuleWrapper from 'src/components/ModuleWrapper';
import Layers from 'src/components/Layers';
import { useBreakpoint } from 'src/components/Breakpoints';
import C2CGradientTitleAnimation from '../C2CGradientTitleAnimation';
import C2COpacityAnimation from '../C2COpacityAnimation';
import C2CScrimAnimation from '../C2CScrimAnimation';
import AppIntegration from '../GridModule/AppIntegration';
import Chevron from 'src/assets/svg/ChevronRight';
import Link from 'src/components/Link';

const Item = ({ image, logo, text, linkText, linkUrl, smallLogo }) => {
  const hasNoLink = typeof linkUrl === 'undefined';
  return (
    <C2CScrimAnimation>
      <div
        css={css({
          borderRadius: 2,
          display: 'flex',
          background: '#08080A',
          overflow: 'hidden',
          minHeight: '100%',
        })}
      >
        <Link
          isBlank
          isDisabled={hasNoLink}
          url={linkUrl}
          css={css({
            cursor: hasNoLink ? 'default' : '',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            '& .link': { color: 'offWhiteAlpha.9' },
            '&:hover .link': { color: 'white' },
          })}
        >
          <Img
            style={{ width: '100%' }}
            {...image}
            sizes={{ ...image.fluid, aspectRatio: 16 / 7 }}
          />
          <Layers css={css({ flexGrow: '1' })}>
            <Box
              flexDirection="column"
              justifyContent="space-between"
              css={css({
                p: 4,
                position: 'relative',
                justifyContent: 'flex-start',
              })}
            >
              <Box
                css={css({
                  maxWidth: '100%',
                  height: smallLogo ? '20px' : '26px',
                  marginBottom: '19px',
                  transform: 'translate3d(0, 0, 0)',
                  '& svg': {
                    maxHeight: '100%',
                    maxWidth: '180px',
                  },
                })}
              >
                <Img
                  fluid={logo.childImageSharp.fluid}
                  imgStyle={{
                    objectFit: 'contain',
                    objectPosition: 'center left',
                  }}
                  alt="Frame.io, an Adobe company"
                  css={css({ width: '100%', height: '27px' })}
                />
              </Box>
              <Text
                as="p"
                variant="aktiv"
                color="offWhiteAlpha.9"
                fontSize="1rem"
                fontWeight="regular"
              >
                {text}
              </Text>
              {linkText && linkUrl && (
                <Box className="link" css={css({ mt: 'auto' })}>
                  <Box css={css({ mt: 3, alignItems: 'center' })}>
                    <Text
                      variant="aktiv"
                      fontSize="1rem"
                      fontWeight="regular"
                      css={css({
                        color: 'inherit',
                        display: 'flex',
                        borderBottom: 'solid 1px',
                        borderColor: 'offWhiteAlpha.9',
                        pb: 1,
                        transition: 'color 200ms ease',
                        '&:hover': {
                          color: 'white',
                        },
                      })}
                    >
                      {linkText}
                    </Text>
                    <Chevron
                      css={css({
                        ml: 1,
                        mb: '0.2rem',
                        size: '0.8rem',
                        fill: 'offWhiteAlpha.9',
                      })}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Layers>
        </Link>
      </div>
    </C2CScrimAnimation>
  );
};

export default () => {
  const isLarge = useBreakpoint(0);
  const {
    teradekImage,
    soundDevicesImage,
    atomosImage,
    aatonImage,
    vivianaImage,
    fdxImage,
    filmicProIntegration,
    colorFrontIntegration,
    pomfortIntegration,
    magicFinderIntegration,
    zoeLogIntegration,
    teradekLogo,
    sounddevicesLogo,
    aatonLogo,
    atomosLogo,
    fdxLogo,
    vivianaLogo,
  } = useStaticQuery(graphql`
    query {
      teradekLogo: file(relativePath: { eq: "svg/logos/teradek.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      sounddevicesLogo: file(
        relativePath: { eq: "svg/logos/sound-devices.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      aatonLogo: file(relativePath: { eq: "svg/logos/aaton.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      fdxLogo: file(relativePath: { eq: "svg/logos/fdx.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      vivianaLogo: file(relativePath: { eq: "svg/logos/viviana.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      atomosLogo: file(relativePath: { eq: "svg/logos/atomos.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      filmicProIntegration: contentfulAppIntegration(
        slug: { eq: "filmic-pro-integration-c2c" }
      ) {
        ...GridModuleAppIntegrationFragment
      }
      colorFrontIntegration: contentfulAppIntegration(
        slug: { eq: "colorfront-integration-c2c" }
      ) {
        ...GridModuleAppIntegrationFragment
      }
      pomfortIntegration: contentfulAppIntegration(
        slug: { eq: "pomfort-integration-c2c" }
      ) {
        ...GridModuleAppIntegrationFragment
      }
      magicFinderIntegration: contentfulAppIntegration(
        slug: { eq: "magincfinder-integration-c2c" }
      ) {
        ...GridModuleAppIntegrationFragment
      }
      zoeLogIntegration: contentfulAppIntegration(
        slug: { eq: "zoe-log-integration-c2c" }
      ) {
        ...GridModuleAppIntegrationFragment
      }
      teradekImage: contentfulAsset(title: { eq: "c2c-partners-teradek" }) {
        title
        fluid(maxWidth: 1000, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      soundDevicesImage: contentfulAsset(
        title: { eq: "c2c-partners-sound-devices" }
      ) {
        title
        fluid(maxWidth: 1000, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      aatonImage: contentfulAsset(title: { eq: "c2c-partners-aaton" }) {
        title
        fluid(maxWidth: 1000, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      atomosImage: contentfulAsset(title: { eq: "c2c-partners-atomos" }) {
        title
        fluid(maxWidth: 1000, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      fdxImage: contentfulAsset(title: { eq: "c2c-partners-fdx" }) {
        title
        fluid(maxWidth: 1000, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      vivianaImage: contentfulAsset(title: { eq: "c2c-partners-viviana" }) {
        title
        fluid(maxWidth: 1000, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  `);
  return (
    <ModuleWrapper
      background={{ color: 'black' }}
      css={css({
        px: 'pageMargin',
        pb: 'sectionMargin',
      })}
    >
      <TextStack
        textAlign="center"
        alignItems="center"
        css={css({
          mb: 5,
          // pl: ['pageMargin', 0],
          // maxWidth: ['24rem', 'none'],
        })}
      >
        <C2CGradientTitleAnimation css={css({ maxWidth: '18em' })}>
          Camera to Cloud certified partners
        </C2CGradientTitleAnimation>
      </TextStack>
      <C2COpacityAnimation>
        <Text
          as="h3"
          variant="heading.m"
          color="offWhiteAlpha.7"
          css={css({
            pt: '2rem',
            pb: '3rem',
            textAlign: 'center',
          })}
        >
          Hardware partners
        </Text>
      </C2COpacityAnimation>
      <Grid
        g={[4, null, null, 5]}
        gridTemplateColumns={['1fr', null, null, '1fr 1fr', '1fr 1fr 1fr']}
        css={css({
          gridTemplateRows: '1fr',
          pb: 'pageMargin',
        })}
      >
        <Item
          image={teradekImage}
          logo={teradekLogo}
          text="The Teradek Serv 4K delivers a massive leap in quality by capturing timecode-accurate, 10bit, 4K HDR HEVC proxies at up to 85Mbps. Files are encoded in BT2084 /PQ and automatically uploaded to Frame.io over Wi-Fi or cellular."
          linkText="Visit Teradek"
          linkUrl="https://teradek.com/pages/serv-4k"
        />
        <Item
          image={atomosImage}
          logo={atomosLogo}
          text="Use the Atomos Connect Wi-Fi 6 module for existing Ninja V/V+ recorders or a 7” Shogun Connect recorder to capture finishing-quality ProRes or DNx files. Lower-bandwidth 1080p, H.265 (HEVC) proxy files are simultaneously generated and automatically uploaded."
          linkText="Visit Atomos"
          linkUrl="https://www.atomos.com"
        />
        <Item
          image={soundDevicesImage}
          logo={sounddevicesLogo}
          text="Sound Devices designs and manufactures the leading production sound recorders. The latest 888 and Scorpio recordders capture and transmit original audio files directly into Frame.io."
          linkText="Visit Sound Devices"
          linkUrl="https://www.sounddevices.com/product/888/"
        />
        <Item
          image={aatonImage}
          logo={aatonLogo}
          text="Aaton builds professional multi-track on-location audio mixers and recorders. The Cantar X3 sends uncompressed WAV files directly to Frame.io via LTE or Wi-Fi from the set."
          linkText="Visit Aaton"
          linkUrl="https://www.aaton.com/cantar-x3/overview"
        />
        <Item
          image={vivianaImage}
          logo={vivianaLogo}
          text="Viviana Cloud hardware and the Viviana iPhone app expands Camera to Cloud, making it compatible with a wide range of affordable field sound recorders from Sound Devices, Zoom, and others."
          linkText="Visit Viviana"
          linkUrl="https://www.vivianacloud.com"
        />
        <Item
          image={fdxImage}
          logo={fdxLogo}
          text="The all-in-one solution for backing up, transcoding, and uploading media from hard drives and SD cards."
          linkText="Visit Filmdatabox"
          linkUrl="https://www.filmdatabox.com/en/"
        />
      </Grid>
      <C2COpacityAnimation>
        <Text
          as="h3"
          variant="heading.m"
          color="offWhiteAlpha.7"
          css={css({
            pt: '2rem',
            pb: '3rem',
            textAlign: 'center',
          })}
        >
          Software partners
        </Text>
      </C2COpacityAnimation>
      <Grid
        g={[3]}
        gridTemplateColumns={['1fr', null, '1fr 1fr', null, null]}
        css={css({
          gridTemplateRows: '1fr',
          pb: 'pageMargin',
          mx: 'auto',
          maxWidth: '80rem',
        })}
      >
        <C2CScrimAnimation>
          <AppIntegration {...filmicProIntegration} />
        </C2CScrimAnimation>
        <C2CScrimAnimation>
          <AppIntegration {...colorFrontIntegration} />
        </C2CScrimAnimation>
        <C2CScrimAnimation>
          <AppIntegration {...pomfortIntegration} />
        </C2CScrimAnimation>
        <C2CScrimAnimation>
          <AppIntegration {...zoeLogIntegration} />
        </C2CScrimAnimation>
        <C2CScrimAnimation>
          <AppIntegration {...magicFinderIntegration} />
        </C2CScrimAnimation>
      </Grid>
    </ModuleWrapper>
  );
};
