export function inOutQuint(x) {
  return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
}

export function inQuint(x) {
  return x * x * x * x * x;
}

export function outQuint(x) {
  return 1 - Math.pow(1 - x, 5);
}

export function linear(x) {
  return x;
}

export function inOutCubic(x) {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

export function outCubic(x) {
  return 1 - Math.pow(1 - x, 3);
}

export function inCubic(x) {
  return x * x * x;
}

export function outExpo(x) {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
}
