import React from 'react';
import Img from 'gatsby-image';
import css from 'src/theme/css';
import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import ModuleWrapper from 'src/components/ModuleWrapper';
import Layers from 'src/components/Layers';
import { Flex } from 'src/components/FlexBox';
import { useBreakpoint } from 'src/components/Breakpoints';
import C2COpacityAnimation from '../C2COpacityAnimation';
import C2CGradientTitleAnimation from '../C2CGradientTitleAnimation';

export default ({ gradient, gradientSmall }) => {
  const isLarge = useBreakpoint(0);

  return (
    <ModuleWrapper
      css={css({
        px: 'pageMargin',
      })}
      background={{
        color: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))',
      }}
    >
      <Flex
        gy={5}
        flexDirection="column"
        css={css({
          py: 'sectionMargin',
        })}
      >
        <Layers
          css={css({
            bg: 'c2cGradientToBottomLeft',
            borderRadius: 2,
            overflow: 'hidden',
          })}
        >
          <Img
            {...(isLarge ? gradient : gradientSmall)}
            style={{ position: 'absolute' }}
          />
          <TextStack
            textAlign="center"
            alignItems="center"
            css={css({ py: 'pageMargin', px: 'pageMargin' })}
            style={{ position: 'relative' }}
          >
            <C2CGradientTitleAnimation
              colors={['rgba(235, 239, 255, 1)', '#9dd5f9', '#1d1ed400']}
              css={css({ maxWidth: '14em' })}
            >
              Save&nbsp;time. Work&nbsp;faster.
              <br />
              Tell better&nbsp;stories.
            </C2CGradientTitleAnimation>
            <C2COpacityAnimation>
              <Text
                as="p"
                variant="aktiv"
                color="offWhiteAlpha.9"
                css={css({ maxWidth: '30em' })}
              >
                Camera to Cloud eliminates the delay between production and
                post. Audio and video are instantly uploaded from the set to
                Frame.io between each take. Now everyone can collaborate right
                from the start.
              </Text>
            </C2COpacityAnimation>
          </TextStack>
        </Layers>
      </Flex>
    </ModuleWrapper>
  );
};
