import React from 'react';
import Img from 'gatsby-image';
import css from 'src/theme/css';
import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import { Grid, Box } from 'src/components/FlexBox';
import ModuleWrapper from 'src/components/ModuleWrapper';
import SmartButton from 'src/components/SmartButton';
import C2COpacityAnimation from '../C2COpacityAnimation';
import C2CGradientTitleAnimation from '../C2CGradientTitleAnimation';
import Layers from 'src/components/Layers';

export default ({ developerImage }) => {
  return (
    <ModuleWrapper
      colorMode="light"
      css={css({
        px: 'pageMargin',
        py: 'sectionMargin',
      })}
      background={{ color: 'antiMode' }}
    >
      <Grid
        gx={6}
        gy={5}
        gridTemplateColumns={['auto', null, '1fr 1fr']}
        alignItems="center"
        justifyItems="stretch"
      >
        <TextStack
          // alignItems="center"
          css={css({
            // pl: ['pageMargin', null, 0]
            // pl: [null, null, 'pageMargin'],
            alignItems: ['center', null, 'start'],
            textAlign: ['center', null, 'left'],
          })}
        >
          <C2CGradientTitleAnimation
            colors={[
              '#000000',
              '#1d1ed4',
              '#7d28e1',
              '#9dd5f9',
              'rgba(235, 239, 255, 0)',
            ]}
            color="#000"
            variant="heading.xxl"
            css={css({ maxWidth: '14em' })}
          >
            Become a<br />
            C2C partner
          </C2CGradientTitleAnimation>
          <C2COpacityAnimation>
            <Text as="p" variant="aktiv" css={css({ maxWidth: '30em' })}>
              Now you can be part of building out the Camera to Cloud ecosystem
              by integrating your cameras or devices with Frame.io. To kickstart
              our Cloud Devices API, we're releasing sample codes in Go, Swift,
              and C++ to invite you to our rapidly growing community of Frame.io
              developer partners.
            </Text>
          </C2COpacityAnimation>
          <C2COpacityAnimation>
            <SmartButton
              variant="C2C"
              action="Link"
              url="https://developer.frame.io"
              label="See documentation"
            />
          </C2COpacityAnimation>
        </TextStack>
        <Layers
          css={css({
            display: ['none', null, 'block'],
            width: [null, null, 'calc(100% + var(--page-margin))', '100%'],
          })}
        >
          <Box
            css={css({
              bg: 'c2cGlow',
              backgroundSize: 'cover',
              filter: 'blur(40px)',
              opacity: 0.75,
              height: '80%',
              bottom: 0,
              top: 'auto',
            })}
          />
          <Box
            flexDirection="column"
            css={css({
              bg: '#212d63',
              position: 'relative',
              overflow: 'hidden',
              borderRadius: 2,
              borderTopRightRadius: [null, null, 0, 2],
              borderBottomRightRadius: [null, null, 0, 2],
            })}
          >
            <Img
              {...developerImage}
              css={css({
                width: '100%',
                maxWidth: ['40rem', null, 'none'],
              })}
            />
          </Box>
        </Layers>
      </Grid>
    </ModuleWrapper>
  );
};
