import React, { useRef } from 'react';
import { progress, clamp } from '@popmotion/popcorn';
import { useStore } from 'src/components/GlobalState';
import useAnimationFrame from 'src/hooks/useAnimationFrame';
import useBounds from 'src/hooks/useBounds';
import * as ease from 'src/utils/ease';
import { useBreakpoint } from '../Breakpoints';

const C2COpacityAnimation = ({ children, css, ...other }) => {
  const reflow = useStore((state) => state.reflow);
  const [outer, bounds] = useBounds();
  const inner = useRef();

  useAnimationFrame(() => {
    if (outer.current && inner.current && bounds.current) {
      const p = clamp(
        0,
        1,
        ease.outCubic(
          progress(reflow.height * 0.9, reflow.height * 0.5, bounds.current.y)
        )
      );
      inner.current.style.opacity = p;
    }
  });

  return (
    <div ref={outer} css={css} {...other}>
      <div ref={inner} style={{ opacity: 0 }}>
        {children}
      </div>
    </div>
  );
};

export default ({ children, ...props }) => {
  const isLarge = useBreakpoint(0);
  if (isLarge) {
    return <C2COpacityAnimation {...props}>{children}</C2COpacityAnimation>;
  } else {
    return children;
  }
};
