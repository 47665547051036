import React, { useRef } from 'react';
import { useStore } from 'src/components/GlobalState';
import useAnimationFrame from 'src/hooks/useAnimationFrame';
import useBounds from 'src/hooks/useBounds';
import { progress, mix } from '@popmotion/popcorn';
import css from 'src/theme/css';
import { useBreakpoint } from '../Breakpoints';

const C2CScrimAnimation = ({ children, ...other }) => {
  const reflow = useStore((state) => state.reflow);
  const [outer, bounds] = useBounds();
  const scrim = useRef();

  useAnimationFrame(() => {
    if (outer.current && scrim.current && bounds.current) {
      const y = reflow.height * 0.6 - bounds.current.y;
      scrim.current.style.transform = `translateY(${y}px)`;
      scrim.current.style.opacity = mix(
        1,
        0,
        progress(reflow.height * 0.8, reflow.height * 0.4, bounds.current.y)
      );
    }
  });

  return (
    <div
      ref={outer}
      {...other}
      css={css({ position: 'relative', overflow: 'hidden' })}
    >
      {children}
      <div
        ref={scrim}
        style={{ opacity: 1, transform: 'translateY(-100%)' }}
        css={css({
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100vh',
          width: '100%',
          bg: 'linear-gradient(to bottom, transparent, black 40%, black)',
        })}
      />
    </div>
  );
};

export default ({ children, ...other }) => {
  const isLarge = useBreakpoint(0);
  if (isLarge) {
    return <C2CScrimAnimation {...other}>{children}</C2CScrimAnimation>;
  } else {
    return children;
  }
};
