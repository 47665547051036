import React, { useEffect, useRef, memo } from 'react';
import { animate } from 'popmotion';
import Img from 'gatsby-image';
import Text from 'src/components/Text';
import css from 'src/theme/css';
import { Flex, Box } from 'src/components/FlexBox';
import ModuleWrapper from 'src/components/ModuleWrapper';
import * as ease from 'src/utils/ease';
import SmartButton, * as smartButton from 'src/components/SmartButton';
import useAwaitImageLoad from 'src/hooks/useAwaitImageLoad';

const colors = [
  'rgba(235, 239, 255, 1)',
  '#9dd5f9',
  '#7d28e1',
  '#1d1ed4',
  '#00000000',
];

const first = colors[0];
const last = colors[colors.length - 1];
const rest = colors.length > 2 ? colors.slice(1, -1).join(', ') + ',' : '';

const TitleAnimation = memo(({ children, isReady }) => {
  const ref = useRef();
  useEffect(() => {
    if (isReady) {
      animate({
        from: -100,
        to: 100,
        duration: 4000,
        ease: ease.outCubic,
        onUpdate: (p) => {
          if (ref.current) {
            ref.current.style.backgroundImage = `linear-gradient(
            160deg,
            ${first} ${p}%,
            ${rest}
            ${last} ${p + 100}%
            )`;
          }
        },
      });
    }
  }, [isReady]);
  return (
    <span
      ref={ref}
      css={css({
        display: 'inline',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      })}
    >
      {children}
    </span>
  );
});

export default ({ isReady, iPadImage, ringImage }) => {
  const { onStartLoad, onLoad } = useAwaitImageLoad();

  const titleRef = useRef();
  const button1Ref = useRef();
  const button2Ref = useRef();
  const iPadRef = useRef();
  const bodyText = useRef();

  useEffect(() => {
    if (isReady) {
      if (titleRef.current) {
        animate({
          from: 0,
          to: 1,
          duration: 1500,
          elapsed: -300,
          ease: ease.outExpo,
          onUpdate: (p) => {
            const y = (1 - p) * 50;
            titleRef.current.style.transform = `translate3d(0, ${y}%, 0)`;
            titleRef.current.style.opacity = p;
          },
        });
      }
      if (iPadRef.current) {
        animate({
          from: 0,
          to: 1,
          duration: 2000,
          elapsed: -400,
          ease: ease.outExpo,
          onUpdate: (p) => {
            const y = (1 - p) * 25;
            iPadRef.current.style.transform = `translate3d(0, ${y}%, 0)`;
            iPadRef.current.style.opacity = p;
          },
        });
      }
      if (bodyText.current) {
        animate({
          from: 0,
          to: 1,
          duration: 1500,
          elapsed: -400,
          ease: ease.outExpo,
          onUpdate: (p) => {
            const y = (1 - p) * 50;
            bodyText.current.style.transform = `translate3d(0, ${y}%, 0)`;
            bodyText.current.style.opacity = p;
          },
        });
      }
      if (button1Ref.current) {
        animate({
          from: 0,
          to: 1,
          duration: 800,
          elapsed: -1200,
          ease: ease.outExpo,
          onUpdate: (p) => {
            const y = (1 - p) * 50;
            button1Ref.current.style.transform = `translate3d(0, ${y}%, 0)`;
            button1Ref.current.style.opacity = p;
          },
        });
      }
      if (button2Ref.current) {
        animate({
          from: 0,
          to: 1,
          duration: 800,
          elapsed: -1300,
          ease: ease.outExpo,
          onUpdate: (p) => {
            const y = (1 - p) * -50;
            button2Ref.current.style.transform = `translate3d(0, ${y}%, 0)`;
            button2Ref.current.style.opacity = p;
          },
        });
      }
    }
  }, [isReady]);

  return (
    <ModuleWrapper
      flexDirection="column"
      alignItems="center"
      css={css({
        pt: 'sectionMargin',
        px: 'pageMargin',
      })}
      background={{
        objectFit: 'contain',
        objectPosition: 'bottom',
        smallImage: {
          ...ringImage,
          onLoad: () => onLoad('hero-img'),
          onStartLoad: (wasCached) => onStartLoad('hero-img', wasCached),
        },
      }}
    >
      <Box flexDirection="column" alignItems="center" justifyContent="flex-end">
        <Text
          as="h1"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          variant="heading.xxxl"
          ref={titleRef}
          css={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: ['5.416rem', '6.625rem'],
          })}
        >
          <TitleAnimation isReady={isReady}>Camera to Cloud</TitleAnimation>
        </Text>
      </Box>
      <Text
        as="p"
        variant="heading.xl"
        textAlign="center"
        color="offWhite"
        css={css({
          mt: 5,
          pb: 5,
          fontSize: ['2.666rem', '3rem'],
          maxWidth: ['10em', '11em', '17em'],
          opacity: 0,
        })}
        ref={bodyText}
      >
        <span
          css={css({
            background: 'c2cGradientRadial',
            display: 'inline-block',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          })}
        >
          The fastest, easiest, and most secure
        </span>{' '}
        way to automatically get footage from cameras to collaborators—anywhere
        in the world.
      </Text>
      <Flex
        gx={4}
        gy={2}
        alignItems="center"
        flexDirection={['column', 'row']}
        css={css({ mt: [2, 1], mb: [4, 6], opacity: 0 })}
        ref={button1Ref}
      >
        <SmartButton
          action={smartButton.actions.videoModal}
          label="WATCH THE FILM"
          videoUrl={'https://frame.wistia.com/medias/fuceu33gdd'}
          variant="C2CPlaySmall"
        ></SmartButton>
      </Flex>
      <Box
        flexDirection="column"
        ref={iPadRef}
        css={css({
          width: '100%',
          maxWidth: '90rem',
          opacity: 0,
          px: [0, null, null, 5],
          pb: [0, null, 'sectionMargin'],
          mb: ['-20%', null, 0],
        })}
      >
        <Img
          onLoad={() => onLoad('iPadImage')}
          onStartLoad={(wasCached) => onStartLoad('iPadImage', wasCached)}
          {...iPadImage}
        />
      </Box>
    </ModuleWrapper>
  );
};
