import React, { memo, useState, useEffect, useRef } from 'react';
import { useStore } from 'src/components/GlobalState';
import range from 'lodash/range';
import { canvasRGB as blur } from 'stackblur-canvas';
import useAnimationFrame from 'src/hooks/useAnimationFrame';
import { clamp, interpolate, animate } from 'popmotion';
import * as ease from 'src/utils/ease';

const iterations = 8;

const useImage = (src) => {
  const [imageElement, setImageElement] = useState();

  useEffect(() => {
    const element = document.createElement('img');
    element.addEventListener('load', function onLoad() {
      setImageElement(element);
      element.removeEventListener('load', onLoad);
    });
    element.crossOrigin = 'Anonymous';
    element.src = src;
  }, [src, setImageElement]);

  return imageElement;
};

function drawFullImage(context, image, canvasWidth, canvasHeight, aspectRatio) {
  context.drawImage(
    image,
    0,
    Math.min(0, (canvasHeight - canvasWidth * aspectRatio) * 0.8),
    canvasWidth,
    canvasWidth * aspectRatio
  );
}

function createCanvasArray(image, width, height, imageAspectRatio) {
  return range(iterations).map((i) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const intensity = Math.pow(2, i);
    canvas.width = width / intensity;
    canvas.height = height / intensity;
    context.fillStyle = 'black';
    context.fillRect(0, 0, width, height);
    drawFullImage(
      context,
      image,
      canvas.width,
      canvas.height,
      imageAspectRatio
    );
    blur(canvas, 0, 0, canvas.width, canvas.height, Math.min(width / 500, 10));
    return canvas;
  });
}

export default memo(({ image, isReady, setIsReady }) => {
  const introAnimation = useRef(0);
  const reflow = useStore((state) => state.reflow);
  const scroll = useRef(useStore.getState().scroll);
  const canvasElement = useRef();
  const canvasArray = useRef();
  const context = useRef();
  const imageElement = useImage(image.file.url);
  const aspectRatio =
    image.file.details.image.height / image.file.details.image.width;

  useEffect(
    () =>
      useStore.subscribe(
        (_scroll) => (scroll.current = _scroll),
        (state) => state.scroll
      ),
    []
  );

  useEffect(() => {
    context.current = canvasElement.current.getContext('2d');
  }, []);

  useEffect(() => {
    if (canvasElement.current) {
      canvasElement.current.width = reflow.width;
      canvasElement.current.height = reflow.height;
    }
    if (imageElement) {
      canvasArray.current = createCanvasArray(
        imageElement,
        reflow.width,
        reflow.height,
        aspectRatio
      );
      setIsReady(true);
    }
  }, [reflow, aspectRatio, imageElement, setIsReady]);

  useAnimationFrame(() => {
    if (context.current && canvasArray.current) {
      const p = Math.max(
        interpolate([0, reflow.height * 2], [0, 1], {
          ease: ease.outCubic,
        })(scroll.current.y),
        introAnimation.current
      );
      context.current.globalAlpha = 1;
      context.current.fillStyle = 'black';
      context.current.fillRect(0, 0, reflow.width, reflow.height);
      drawFullImage(
        context.current,
        imageElement,
        reflow.width,
        reflow.height,
        aspectRatio
      );
      canvasArray.current.forEach((canvas, i) => {
        context.current.globalAlpha = clamp(
          0,
          1,
          p * canvasArray.current.length - i
        );
        context.current.drawImage(canvas, 0, 0, reflow.width, reflow.height);
      });
    }
  });

  useEffect(() => {
    if (isReady) {
      animate({
        from: 1,
        to: 0,
        duration: 2000,
        ease: (x) => x,
        onUpdate: (p) => {
          introAnimation.current = p;
        },
      });

      animate({
        from: 0,
        to: 1,
        duration: 1000,
        elapsed: -20,
        ease: ease.outCubic,
        onUpdate: (p) => {
          canvasElement.current.style.opacity = p;
        },
      });
    }
  }, [isReady]);

  return (
    <canvas
      ref={canvasElement}
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        opacity: 0,
      }}
    />
  );
});
