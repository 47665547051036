import React from 'react';
import Img from 'gatsby-image';
import ModuleWrapper from 'src/components/ModuleWrapper';
import css from 'src/theme/css';
import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import { Flex, Grid, Box } from 'src/components/FlexBox';
import C2COpacityAnimation from 'src/components/C2COpacityAnimation';
import C2CGradientTitleAnimation from 'src/components/C2CGradientTitleAnimation';
import C2CScrimAnimation from 'src/components/C2CScrimAnimation';
import Layers from 'src/components/Layers';
import ComingSoonIcon from 'src/assets/svg/c2c-coming-soon.inline.svg';

const Item = ({ image, title, text }) => {
  return (
    <C2CScrimAnimation>
      <div
        css={css({
          borderRadius: 2,
          background: '#08080A',
          overflow: 'hidden',
        })}
      >
        <Img style={{ width: '100%' }} objectPosition="top" {...image} />
        <Layers>
          <Box
            flexDirection="column"
            justifyContent="space-between"
            css={css({ p: 4, position: 'relative' })}
          >
            <Text
              as="p"
              variant="heading.xs"
              color="offWhiteAlpha.9"
              css={css({ pb: 2 })}
            >
              {title}
            </Text>
            <Text
              as="p"
              variant="aktiv"
              color="offWhiteAlpha.9"
              fontSize="1rem"
              fontWeight="regular"
            >
              {text}
            </Text>
          </Box>
        </Layers>
      </div>
    </C2CScrimAnimation>
  );
};

const TwoUp = ({ title, body, image, flipped, subtitle, isComingSoon }) => {
  return (
    <Grid
      gx="3rem"
      gy="4rem"
      gridTemplateColumns={['auto', null, '1fr 1fr']}
      alignItems="center"
      css={css({ width: '100%', px: [0, null, null, null] })}
    >
      <Box
        flexDirection="column"
        css={css({ order: [0, null, flipped ? 1 : 0] })}
        alignItems={['center', null, 'start']}
        textAlign={['center', null, 'start']}
      >
        <C2CGradientTitleAnimation variant="aktivTitle" as="h2">
          {title}
        </C2CGradientTitleAnimation>
        <C2COpacityAnimation>
          <Text
            as="p"
            variant="aktiv"
            color="#cb3d95"
            css={css({
              mt: [3, 2, 1],
              maxWidth: ['20rem', null, 'none'],
            })}
          >
            {subtitle}
          </Text>
        </C2COpacityAnimation>
        <C2COpacityAnimation>
          <Text
            as="p"
            variant="aktiv"
            color="offWhiteAlpha.8"
            css={css({
              mt: 4,
              maxWidth: '30em',
            })}
          >
            {body}
          </Text>
        </C2COpacityAnimation>
      </Box>

      <Layers
        flexDirection="column"
        css={css({ borderRadius: 2, overflow: 'hidden', marginBottom: '2rem' })}
      >
        <C2CScrimAnimation>
          {/* <Parallax depth={0.3} scale={1.3}> */}
          <Img {...image} style={{ width: '100%' }} />
          {/* </Parallax> */}
          {isComingSoon && (
            <Box
              css={css({ p: 3, position: 'absolute', top: 0, right: 0 })}
              justifyContent="flex-end"
            >
              <ComingSoonIcon />
            </Box>
          )}
        </C2CScrimAnimation>
      </Layers>
    </Grid>
  );
};

export default ({
  featureImageDigitalCinema,
  featureImageDslr,
  featureImageMobile,
  featureImage1,
  featureImage2,
  featureImage3,
  featureImage4,
  featureHero,
}) => {
  return (
    <>
      {featureHero && (
        <ModuleWrapper
          css={css({
            px: 'pageMargin',
            pb: '40px',
            display: ['none', null, 'block'],
          })}
          background={{
            color: 'black',
          }}
        >
          <Layers>
            <Img
              {...featureHero}
              css={css({
                borderRadius: 2,
                overflow: 'hidden',
                width: '100%',
              })}
            />
            <Box
              css={css({
                bg:
                  'linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1))',
              })}
            />
          </Layers>
        </ModuleWrapper>
      )}
      <ModuleWrapper
        css={css({
          pb: 'sectionMargin',
        })}
        background={{
          color: 'black',
        }}
      >
        <Flex
          gy={5}
          flexDirection="column"
          alignItems="center"
          css={css({
            position: 'relative',
            pt: ['sectionMargin', null, 0],
            px: 'pageMargin',
          })}
        >
          <TextStack
            alignItems="center"
            textAlign="center"
            css={css({ px: 'pageMargin' })}
          >
            <C2CGradientTitleAnimation as="h2">
              Connect any kind of camera to the cloud
            </C2CGradientTitleAnimation>
            <C2COpacityAnimation>
              <Text
                as="p"
                variant="aktiv"
                color="offWhiteAlpha.7"
                css={css({
                  maxWidth: '38em',
                })}
              >
                From digital cinema to mirrorless and mobile, pick the type of
                camera that best suits your production. Then choose the video
                quality and workflow to match.
              </Text>
            </C2COpacityAnimation>
          </TextStack>

          <Grid
            g={[4, null, null, 5]}
            gridTemplateColumns={['1fr', null, '1fr 1fr 1fr', null, null]}
            css={css({
              pb: 'pageMargin',
            })}
          >
            <Item
              image={featureImageDigitalCinema}
              title="Digital cinema"
              text="Connect the most advanced cinema cameras from ARRI, Canon, Panavision, RED, or Sony to Camera to Cloud using a Teradek Serv 4K."
            />
            <Item
              image={featureImageDslr}
              title="Mirrorless and DSLR"
              text="Use Atomos recorders to capture via HDMI and upload from a wide range of mirrorless and DSLR cameras including Canon, Nikon, Panasonic LUMIX, and Sony."
            />
            <Item
              image={featureImageMobile}
              title="iPhone and Android"
              text="Download FiLMiC Pro app for iPhone or Android and you can use C2C to share takes of your vlogs or influencer videos with clients."
            />
          </Grid>

          <TwoUp
            flipped
            title="Connect to the cloud"
            subtitle="Easy to set up and fast to use."
            body="No matter where you're shooting, assets can be securely shared with anyone who needs them the moment the director calls “cut.”"
            image={featureImage1}
          />
          <TwoUp
            title="Create proxies good enough to post"
            subtitle="Online or offline — the choice is yours."
            body="Get bandwidth-efficient, high-quality proxy files that are good enough for editing and small enough to use for posting to social media. Proxy files can be swapped with the camera originals for high-end finishing workflows."
            image={featureImage2}
          />
          <TwoUp
            flipped
            title="Keep everything organized"
            subtitle="Asset organization simplified."
            body="Centralize your production assets in the cloud so they're easy to track. Files are automatically organized into logical folders by date, file type, and device. "
            image={featureImage3}
          />
          <TwoUp
            title="Go beyond sound and picture"
            subtitle="Designed to work with all your production files."
            body="Use Camera to Cloud connected apps on set to share sound reports, camera logs, and files like Color Decision Lists. "
            image={featureImage4}
          />
          <TextStack
            alignItems="center"
            textAlign="center"
            css={css({ px: 'pageMargin' })}
          >
            <C2CGradientTitleAnimation as="h2">
              Now playing everywhere
            </C2CGradientTitleAnimation>
            <C2COpacityAnimation>
              <Text
                as="p"
                variant="aktiv"
                color="offWhiteAlpha.7"
                css={css({
                  maxWidth: '38em',
                })}
              >
                Camera to Cloud has forever changed film, television, news, and
                sports workflows. From network primetime shows to social content
                for the Golden State Warriors and feature films like Michael
                Bay's Songbird, Camera to Cloud is the fastest growing
                production technology to emerge in years.
              </Text>
            </C2COpacityAnimation>
          </TextStack>

          <C2COpacityAnimation
            css={css({
              width: '100%',
              marginBottom: '2rem',
            })}
          >
            <Grid
              gridTemplateColumns={['1fr', null, `repeat(3, 1fr)`]}
              gx={4}
              gy={5}
              justifyItems="center"
            >
              <Box
                alignItems="center"
                textAlign="center"
                flexDirection="column"
              >
                <Text variant="heading.xxxl">
                  <b css={css({ px: '0.1ch' })}>2,200+</b>
                </Text>
                <Text variant="small" css={css({ mt: 2 })}>
                  Productions
                </Text>
              </Box>
              <Box
                alignItems="center"
                textAlign="center"
                flexDirection="column"
              >
                <Text variant="heading.xxxl">
                  <b css={css({ px: '0.1ch' })}>280K+</b>
                </Text>
                <Text variant="small" css={css({ mt: 2 })}>
                  Unique takes
                </Text>
              </Box>
              <Box
                alignItems="center"
                textAlign="center"
                flexDirection="column"
              >
                <Text variant="heading.xxxl">
                  <b css={css({ px: '0.1ch' })}>900+</b>
                </Text>
                <Text variant="small" css={css({ mt: 2 })}>
                  Devices connected
                </Text>
              </Box>
            </Grid>
          </C2COpacityAnimation>
        </Flex>
      </ModuleWrapper>
    </>
  );
};
