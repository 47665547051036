import React from 'react';
import css from 'src/theme/css';
import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import ImageGroup from 'src/components/ImageGroup';
import { Flex } from 'src/components/FlexBox';
import ModuleWrapper from 'src/components/ModuleWrapper';
import C2CGradientTitleAnimation from 'src/components/C2CGradientTitleAnimation';
import C2COpacityAnimation from 'src/components/C2COpacityAnimation';

export default ({ featureGraphImage, featureGraphVideo }) => {
  return (
    <ModuleWrapper colorMode="dark">
      <Flex
        gy={5}
        flexDirection="column"
        css={css({
          py: 'sectionMargin',
        })}
      >
        <TextStack
          alignItems="center"
          textAlign="center"
          css={css({ px: 'pageMargin' })}
        >
          <C2CGradientTitleAnimation as="h2">
            Production, meet the cloud
          </C2CGradientTitleAnimation>
          <C2COpacityAnimation>
            <Text
              as="p"
              variant="aktiv"
              color="offWhiteAlpha.7"
              css={css({
                maxWidth: '38em',
              })}
            >
              A secure next-generation workflow designed to instantly connect
              production to post so you can work faster and stay more creative.
            </Text>
          </C2COpacityAnimation>
        </TextStack>
        <C2COpacityAnimation>
          <div
            css={css({
              width: '100%',
              px: [0, 'pageMargin'],
            })}
          >
            <ImageGroup
              largeImage={featureGraphImage}
              smallImage={featureGraphImage}
              video={featureGraphVideo}
              css={css({
                borderRadius: 2,
                overflow: 'hidden',
                transform: 'translate3d(0, 0, 0)',
                mb: 4,
              })}
            />
          </div>
        </C2COpacityAnimation>
      </Flex>
    </ModuleWrapper>
  );
};
